// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
}

.slogan {
    background-color: #FFFFFF;
}

.slogan_container {
    width: 1400px;
    margin: 0 auto;
}

.slogan_text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 100px 0;
    color: #0047FF;
    font-size: 62px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/slogan/Slogan.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,cAAc;IACd,eAAe;IACf,kBAAkB;AACtB","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    outline: none;\n    box-sizing: border-box;\n}\n\n.slogan {\n    background-color: #FFFFFF;\n}\n\n.slogan_container {\n    width: 1400px;\n    margin: 0 auto;\n}\n\n.slogan_text {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin: 100px 0;\n    color: #0047FF;\n    font-size: 62px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
