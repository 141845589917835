// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container {
  text-align: center;
  padding: 50px;
  height: 60vh;
}

.not-found-container h1 {
  font-size: 10rem;
  margin: 0;
}

.not-found-container h2 {
  font-size: 2rem;
  margin: 20px 0;
}

.not-found-container p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.not-found-container .back-home {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
  font-size: 1.2rem;
}

.not-found-container .back-home:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/not_found/NotFound.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".not-found-container {\n  text-align: center;\n  padding: 50px;\n  height: 60vh;\n}\n\n.not-found-container h1 {\n  font-size: 10rem;\n  margin: 0;\n}\n\n.not-found-container h2 {\n  font-size: 2rem;\n  margin: 20px 0;\n}\n\n.not-found-container p {\n  font-size: 1.2rem;\n  margin-bottom: 30px;\n}\n\n.not-found-container .back-home {\n  text-decoration: none;\n  color: #007bff;\n  font-weight: bold;\n  font-size: 1.2rem;\n}\n\n.not-found-container .back-home:hover {\n  text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
