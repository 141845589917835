// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
}

.branch {
    margin: 20px auto;
}

.branch_container {
    width: 1200px;
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
}

.branch_section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.branch_text {
    margin-top: 20px;
    font-size: 24px;
    font-family: Roboto, sans-serif;
    color: #0047FF;
}

.branch_line_container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.branch_line {
    max-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/branch/Branch.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,+BAA+B;IAC/B,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    outline: none;\n    box-sizing: border-box;\n}\n\n.branch {\n    margin: 20px auto;\n}\n\n.branch_container {\n    width: 1200px;\n    margin: 50px auto;\n    display: flex;\n    justify-content: space-between;\n}\n\n.branch_section {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.branch_text {\n    margin-top: 20px;\n    font-size: 24px;\n    font-family: Roboto, sans-serif;\n    color: #0047FF;\n}\n\n.branch_line_container {\n    display: flex;\n    justify-content: center;\n    margin: 20px 0;\n}\n\n.branch_line {\n    max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
