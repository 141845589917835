// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
}

.hero {
    margin: 20px auto;
}

.hero_container{
    width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hero_title {
    color: #0047FF;
    font-size: 96px;
    font-weight: bold;
    width: 1096px;
    margin-left: 120px;
    padding-top: 50px;
    text-align: center;
    font-family: 'IBM Plex Mono', monospace
}

.hero_text {
    color: #0047FF;
    font-size: 24px;
    font-weight: lighter;
    font-family: Roboto, "sans-serif";
    width: 1096px;
    margin-top: 50px;
    margin-left: 120px;
    text-align: center;
}

.hero_cube {
    margin-top: 50px;
}

.hero_button {
    cursor: pointer;
    width: 352px;
    height: 70px;
    border-radius: 20px;
    color: #FFFFFF;
    font-size: 24px;
    /*font-family: Roboto, "sans-serif";*/
    background-color: #0047FF;
    margin-top: 50px;
    align-items: center;
    transition-duration: 0.5s;
}

.hero_button:hover {
    background-color: #2AA4E8;
}`, "",{"version":3,"sources":["webpack://./src/components/hero/Hero.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB;AACJ;;AAEA;IACI,cAAc;IACd,eAAe;IACf,oBAAoB;IACpB,iCAAiC;IACjC,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,qCAAqC;IACrC,yBAAyB;IACzB,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    outline: none;\n    box-sizing: border-box;\n}\n\n.hero {\n    margin: 20px auto;\n}\n\n.hero_container{\n    width: 1400px;\n    margin: 0 auto;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.hero_title {\n    color: #0047FF;\n    font-size: 96px;\n    font-weight: bold;\n    width: 1096px;\n    margin-left: 120px;\n    padding-top: 50px;\n    text-align: center;\n    font-family: 'IBM Plex Mono', monospace\n}\n\n.hero_text {\n    color: #0047FF;\n    font-size: 24px;\n    font-weight: lighter;\n    font-family: Roboto, \"sans-serif\";\n    width: 1096px;\n    margin-top: 50px;\n    margin-left: 120px;\n    text-align: center;\n}\n\n.hero_cube {\n    margin-top: 50px;\n}\n\n.hero_button {\n    cursor: pointer;\n    width: 352px;\n    height: 70px;\n    border-radius: 20px;\n    color: #FFFFFF;\n    font-size: 24px;\n    /*font-family: Roboto, \"sans-serif\";*/\n    background-color: #0047FF;\n    margin-top: 50px;\n    align-items: center;\n    transition-duration: 0.5s;\n}\n\n.hero_button:hover {\n    background-color: #2AA4E8;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
