// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
}

.hr {
    width: 1200px;
    background-color: #0047FF;
    height: 3px;
    margin-left: 100px;
}

.footer {
    background-color: #FBF8F8;
}

.footer_container {
    width: 1400px;
    height: 270px;
    margin: 0 auto;
}

.footer_logo {
    margin-top: 30px;
    margin-left: 650px;
}

.footer_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    font-family: Strong, "sans-serif";
}

.footer_bottom_year {
    font-size: 48px;
    color: #0047FF;
}

.footer_contacts {
    display: flex;
    flex-direction: column;
}

.footer_contact_item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.footer_contact_item img {
    margin-right: 10px;
}

.footer_contact_item  {
    font-size: 20px;
}

.footer_phone,
.footer_post {
    color: #0047FF;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;IAChB,iCAAiC;AACrC;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;;IAEI,cAAc;AAClB","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    outline: none;\n    box-sizing: border-box;\n}\n\n.hr {\n    width: 1200px;\n    background-color: #0047FF;\n    height: 3px;\n    margin-left: 100px;\n}\n\n.footer {\n    background-color: #FBF8F8;\n}\n\n.footer_container {\n    width: 1400px;\n    height: 270px;\n    margin: 0 auto;\n}\n\n.footer_logo {\n    margin-top: 30px;\n    margin-left: 650px;\n}\n\n.footer_bottom {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 30px;\n    font-family: Strong, \"sans-serif\";\n}\n\n.footer_bottom_year {\n    font-size: 48px;\n    color: #0047FF;\n}\n\n.footer_contacts {\n    display: flex;\n    flex-direction: column;\n}\n\n.footer_contact_item {\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n}\n\n.footer_contact_item img {\n    margin-right: 10px;\n}\n\n.footer_contact_item  {\n    font-size: 20px;\n}\n\n.footer_phone,\n.footer_post {\n    color: #0047FF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
