// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
}

.header {
    margin: 20px auto;
}

.about_us {
    text-decoration: none;
    color: #0047FF;
}

.header_container {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.header_left {
    display: flex;
}

.logo_text {
    width: 200px;
    height: 56px;
    background-color: #0047FF;
    border-radius: 20px;
    color: #FFFFFF;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    margin-top: 10px;
    font-family: Roboto, "sans-serif";
    text-decoration: none;
}

.header_right {
    width: 320px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    font-family: Roboto, "sans-serif";
    font-weight: lighter;
    color: #0047FF;
}`, "",{"version":3,"sources":["webpack://./src/components/header/Header.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,gBAAgB;IAChB,iCAAiC;IACjC,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;IACf,iCAAiC;IACjC,oBAAoB;IACpB,cAAc;AAClB","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    outline: none;\n    box-sizing: border-box;\n}\n\n.header {\n    margin: 20px auto;\n}\n\n.about_us {\n    text-decoration: none;\n    color: #0047FF;\n}\n\n.header_container {\n    width: 1400px;\n    margin: 0 auto;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    flex-direction: row;\n}\n\n.header_left {\n    display: flex;\n}\n\n.logo_text {\n    width: 200px;\n    height: 56px;\n    background-color: #0047FF;\n    border-radius: 20px;\n    color: #FFFFFF;\n    font-size: 36px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-left: 20px;\n    margin-top: 10px;\n    font-family: Roboto, \"sans-serif\";\n    text-decoration: none;\n}\n\n.header_right {\n    width: 320px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    font-size: 24px;\n    font-family: Roboto, \"sans-serif\";\n    font-weight: lighter;\n    color: #0047FF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
