// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `{
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
}

.services_container{
    width: 1400px;
    margin: 0 auto;
}

.services_title {
    display: flex;
    justify-content: center;
    color: #0047FF;
    font-weight: normal;
    font-size: 48px;
    margin-top: 40px;
}

.services_block {
    display: flex;
    flex-direction: column;
}

.service_one {
    display: flex;
    margin: 40px 0;
}

.service_description {
    display: flex;
    flex-direction: column;
    margin-left: 60px;
}

.service_name {
    font-size: 36px;
}

.service_text {
    font-size: 24px;
    margin-top: 20px;
    width: 800px;
}`, "",{"version":3,"sources":["webpack://./src/components/services/Services.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":["{\n    margin: 0;\n    padding: 0;\n    outline: none;\n    box-sizing: border-box;\n}\n\n.services_container{\n    width: 1400px;\n    margin: 0 auto;\n}\n\n.services_title {\n    display: flex;\n    justify-content: center;\n    color: #0047FF;\n    font-weight: normal;\n    font-size: 48px;\n    margin-top: 40px;\n}\n\n.services_block {\n    display: flex;\n    flex-direction: column;\n}\n\n.service_one {\n    display: flex;\n    margin: 40px 0;\n}\n\n.service_description {\n    display: flex;\n    flex-direction: column;\n    margin-left: 60px;\n}\n\n.service_name {\n    font-size: 36px;\n}\n\n.service_text {\n    font-size: 24px;\n    margin-top: 20px;\n    width: 800px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
