// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
}

span {
    background-color: #0047FF;
    color: #FFFFFF;
}

.web_dev {
    margin: 100px auto;
}

.web_dev_container {
    width: 1400px;
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
}

.web_dev_title {
    font-family: Strong, sans-serif;
    font-size: 48px;
    width: 828px;
}

.web_dev_text {
    width: 804px;
    font-size: 24px;
    margin: 30px 0;
    font-family: Roboto, "sans-serif";
}

.web_dev_row1 {
    display: flex;
    align-items: center;
    font-family: Roboto, "sans-serif";

}

.web_dev_row2 {
    display: flex;
    align-items: center;
    margin-top: 30px;
    font-family: Roboto, "sans-serif";
}

.web_dev_text1,
.web_dev_text2 {
    margin-left: 20px;
    font-size: 20px;

}
`, "",{"version":3,"sources":["webpack://./src/components/web_dev/WebDev.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,+BAA+B;IAC/B,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,cAAc;IACd,iCAAiC;AACrC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,iCAAiC;;AAErC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,iCAAiC;AACrC;;AAEA;;IAEI,iBAAiB;IACjB,eAAe;;AAEnB","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    outline: none;\n    box-sizing: border-box;\n}\n\nspan {\n    background-color: #0047FF;\n    color: #FFFFFF;\n}\n\n.web_dev {\n    margin: 100px auto;\n}\n\n.web_dev_container {\n    width: 1400px;\n    margin: 50px auto;\n    display: flex;\n    justify-content: space-between;\n}\n\n.web_dev_title {\n    font-family: Strong, sans-serif;\n    font-size: 48px;\n    width: 828px;\n}\n\n.web_dev_text {\n    width: 804px;\n    font-size: 24px;\n    margin: 30px 0;\n    font-family: Roboto, \"sans-serif\";\n}\n\n.web_dev_row1 {\n    display: flex;\n    align-items: center;\n    font-family: Roboto, \"sans-serif\";\n\n}\n\n.web_dev_row2 {\n    display: flex;\n    align-items: center;\n    margin-top: 30px;\n    font-family: Roboto, \"sans-serif\";\n}\n\n.web_dev_text1,\n.web_dev_text2 {\n    margin-left: 20px;\n    font-size: 20px;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
