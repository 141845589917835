// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
}


hr {
    width: 180px;
    height: 8px;
    background-color: #FFFFFF;
    margin-top: 30px;
}

.citation {
    background-color: #0047FF;
}

.citation_container {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    position: relative;
}

.citation_right {
    align-self: center;
}

.citation_text {
    align-self: center;
    font-family: Roboto, "sans-serif";
    font-weight: lighter;
    font-size: 42px;
    color: #FFFFFF;
}

.citation_gates {
    display: flex;
    align-items: center;
    font-family: Roboto, "sans-serif";
    font-weight: lighter;
    font-size: 42px;
    color: #FFFFFF;
    margin-top: 30px;
}

.citation_crown {
    position: absolute;
    left: 210px;
    bottom: 400px;
}

.brackets {
    font-size: 72px;
    font-family: Roboto, "sans-serif";
}`, "",{"version":3,"sources":["webpack://./src/components/citation/Citation.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,aAAa;IACb,sBAAsB;AAC1B;;;AAGA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,cAAc;IACd,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,iCAAiC;IACjC,oBAAoB;IACpB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,iCAAiC;IACjC,oBAAoB;IACpB,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,iCAAiC;AACrC","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    outline: none;\n    box-sizing: border-box;\n}\n\n\nhr {\n    width: 180px;\n    height: 8px;\n    background-color: #FFFFFF;\n    margin-top: 30px;\n}\n\n.citation {\n    background-color: #0047FF;\n}\n\n.citation_container {\n    width: 1400px;\n    margin: 0 auto;\n    display: flex;\n    position: relative;\n}\n\n.citation_right {\n    align-self: center;\n}\n\n.citation_text {\n    align-self: center;\n    font-family: Roboto, \"sans-serif\";\n    font-weight: lighter;\n    font-size: 42px;\n    color: #FFFFFF;\n}\n\n.citation_gates {\n    display: flex;\n    align-items: center;\n    font-family: Roboto, \"sans-serif\";\n    font-weight: lighter;\n    font-size: 42px;\n    color: #FFFFFF;\n    margin-top: 30px;\n}\n\n.citation_crown {\n    position: absolute;\n    left: 210px;\n    bottom: 400px;\n}\n\n.brackets {\n    font-size: 72px;\n    font-family: Roboto, \"sans-serif\";\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
